import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import ServicesScrollSec from 'components/ServicesScrollSec/ServicesScrollSec'
import { lg, md, xl } from 'config/variables'
import { images } from 'config/images'

import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { DevOpsServicesProps } from 'components/Props/dev-ops-services'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import DevlopmentApproach from 'components/DevlopmentApproach/DevlopmentApproach'
import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import AwardsRecognition from 'components/AwardsRecognition/AwardsRecognition'
import FaqSection from 'components/FaqSection/FaqSection'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const DevopsServicesPage = styled.div`
  .banner {
    .banner-content {
      max-width: 1110px;
      p {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .buildapp-section {
    margin-bottom: 0px;
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
`

const SDSH = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="software-dev-services services-page"
      location={location}
      data={{
        img: images.contactHs,
        img2x: images.contactHs2x,
        name: 'Hardik Shah',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="DevOps Services to achieve faster development"
        description="Sync your development and operational capabilities to accelerate product lifecycle with our DevOps managed services."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/devops/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/services/devops/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <DevopsServicesPage>
        <Banner
          bannerData={DevOpsServicesProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={DevOpsServicesProps} />
        </div>
        {showContent && (
          <>
            <ServicesScrollSec
              serviceScrollSecdata={DevOpsServicesProps}
              {...props}
            />
            <CaseStudySlider caseStudydata={DevOpsServicesProps} {...props} />
            <ProcessFlow
              ProcessFlowData={DevOpsServicesProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <DevlopmentApproach devlomentapproachdata={DevOpsServicesProps} />
            <CustomerReviewSlider
              customReviewSliderdata={DevOpsServicesProps}
              {...props}
            />
            <Aboutprojectform
              AboutprojectformData={DevOpsServicesProps.AboutprojectformProps}
            />
            <AwardsRecognition awardsRecognitiondata={DevOpsServicesProps} />
            <SimformGuaranteeSec
              SimformGuaranteeProps={DevOpsServicesProps.SimformGuaranteeData}
              posterImage={posterImage}
            />
            <FaqSection faqSectiondata={DevOpsServicesProps} />
            <FeatureResources
              featureresourceData={DevOpsServicesProps}
              {...props}
            />
          </>
        )}
      </DevopsServicesPage>
    </Layout>
  )
})

export default SDSH

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/devops-hub-service-banners@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-cloud-deployment@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-react-native-perfo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog1: file(
      relativePath: { regex: "/blog-cost-optimization@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog2: file(
      relativePath: { regex: "/blog-serverless-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog3: file(
      relativePath: { regex: "/blog-mongodb-mysql@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog1: file(
      relativePath: { regex: "/blog-test-coverage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog2: file(
      relativePath: { regex: "/blog-functional-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog1: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog2: file(relativePath: { regex: "/blog-app-debate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog3: file(
      relativePath: { regex: "/blog-app-database@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog1: file(relativePath: { regex: "/blog-build-scalable@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog2: file(
      relativePath: { regex: "/blog-build-ecommerce@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog3: file(relativePath: { regex: "/blog-react-vue@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog2: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog3: file(
      relativePath: { regex: "/blog-healthcare-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog1: file(
      relativePath: { regex: "/home-automation-using-iot-cover-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog2: file(
      relativePath: { regex: "/industrial-iot-iota-part.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog3: file(relativePath: { regex: "/blog-gps-tracking.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog1: file(relativePath: { regex: "/blog-reactnative@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog2: file(relativePath: { regex: "/blog-roundup2019@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog1: file(
      relativePath: { regex: "/blog-messaging-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog2: file(
      relativePath: { regex: "/blog-like-airbnb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(
      relativePath: { regex: "/dbs-intrinsically-agiles@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/dbs-delivery-mindsets@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/dbs-eminent-expertises@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 250) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/dbs-scalable-teams@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(
      relativePath: { regex: "/dbs-crystal-clear-commu@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
