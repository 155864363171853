import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const DevOpsServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Achieve faster development, fewer bugs, and frequent delivery with{' '}
        <span className="heading-red">reliable DevOps services</span>
      </>
    ),
    bannerContent:
      'Sync your development and operational capabilities to accelerate product lifecycle with our DevOps managed services. With continuous integration and delivery at the focus, we help you automate processes, strengthen app security, upgrade architecture, and more.',
    BannerAlt: 'DevOps Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>Transform Software Delivery, for Good, with Simform</>
    ),
    headDetails: [
      {
        Para: (
          <>
            We offer DevOps services and solutions to help your business attain
            the benefits of an authentic DevOps culture. With the development
            and operations team working together, you can experience a
            high-velocity software delivery process that meets customer
            expectations. Additionally, there are reduced costs, increased fault
            tolerance, smoother releases, reduced risks, and easier maintenance.
            <br /> <br />
            We deliver excellence in DevOps implementations and provide a wide
            array of services, including consulting, infrastructure management,
            and infrastructure as a code. Our DevOps experts make use of the
            right tools, automated processes, and architectural approaches to
            help you achieve continuous deployment for higher profitability.
          </>
        ),
      },
      {
        detailsList: [
          'Seasoned DevOps experts',
          'Cross-functional agile teams',
          'Experts in cloud infrastructure management',
          'Top-notch cloud deployments',
          'Consistent delivery through CI/CD approaches',
          'Rigorous quality assurance and testing',
          'Transparent and delivery-oriented culture',
          'Container orchestration expertise',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },

  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our DevOps <span className="highlight-head"> Services</span>
      </>
    ),
    servicesPara:
      'Aligning business operations and development efforts is the recipe for success in the rapidly changing digital ecosystem. Choose from the range of DevOps services and get your business the much-needed tactical advantage.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'DevOps consulting',
        className: 'custom-software',
        mainPara:
          'We devise the best DevOps implementation strategies to help streamline your workflows, automate CI/CD pipelines, accelerate software delivery, and adhere to the highest development standards. We build you the roadmap to implement DevOps solutions that maximize business efficiency.',
        listItem: [
          {
            title: 'DevOps automation services',
            para:
              'Work smarter with automated workflows and processes to reduce errors, minimize cost, and free up human cognition for business-critical tasks.',
          },
          {
            title: 'DevOps implementation services',
            para:
              'Get actionable and efficient workflows built using DevOps approaches to bring the best out of your existing infrastructure and resources.',
          },
          {
            title: 'AWS DevOps services',
            para:
              'Our cloud DevOps services help you make the most of the excellent AWS ecosystem and use their services and tools to ace cloud infrastructure management for your business.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/devops-consulting/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Cloud infrastructure management',
        className: 'software-testing',
        mainPara:
          'Get your cloud infrastructure ready to support a fast-paced delivery model, powered by DevOps, with your management services. We help you keep downtimes at bay, manage resources efficiently, and scale seamlessly.',
        listItem: [
          {
            title: 'Virtual server management and maintenance',
            para:
              'We manage your virtual servers to deliver superior performance and function smoothly despite traffic fluctuations.',
          },
          {
            title: 'Cloud infrastructure design and consulting',
            para:
              'We consult you on everything to lay the foundation for a robust infrastructure, from cloud infrastructure design to virtualization design.',
          },
          {
            title: 'Enterprise modernization',
            para:
              'Say goodbye to legacy setups and embrace the cloud infrastructure to let your apps perform at their optimum best.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/infrastructure-management-monitoring/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'CI/CD services',
        className: 'enterprise-mobile',
        mainPara:
          'Keep up with evolving market trends and user requirements with DevOps practices that help you deploy and manage CI/CD pipelines. Roll out innovative solutions at a much greater speed while resolving issues at a higher cadence with our expert CI/CD services.',
        listItem: [
          {
            title: 'CI/CD implementation services',
            para:
              'Reap the benefits of continuous integration and continuous delivery pipelines with robust implementations enabling business growth.',
          },
          {
            title: 'CI/CD consulting services',
            para:
              'Know how your business can set up automated integration and delivery pipelines while reducing time to marketing and cutting costs at the same time.',
          },
          {
            title: 'CI/CD pipeline auditing',
            para:
              'Reach maximum efficiency with expert auditing of your CI/CD delivery pipelines and know which tools and platforms will help your cause.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/ci-cd/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Containerization services',
        className: 'web-application',
        mainPara: (
          <>
            Utilize our DevOps cloud services to roll out applications faster
            across platforms with expert containerization and orchestration
            services. We help your business improve performance, streamline
            software delivery, and reduce security risks.
          </>
        ),
        listItem: [
          {
            title: 'Containerization strategy and consulting services',
            para:
              'Get expert Kubernetes and Docker consultation to devise the perfect cloud strategy, in line with DevOps principles, for your business.',
          },
          {
            title: 'Container orchestration',
            para:
              'Free up your team from the manual and mundane tasks with automated container orchestration taking care of scaling, networking, and load balancing.',
          },
          {
            title: 'Containerization experts for hire',
            para:
              'Get seasoned containerization experts to help deliver scalable and secure web apps across public and private cloud services for your business.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/containerization-and-orchestration/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Infrastructure as code',
        className: 'dedicated-software',
        mainPara:
          'Experience the new-age way of infrastructure management with automated provisioning of IT infrastructure with Infrastructure as code services. It allows developers to manage, operate, and maintain infrastructure with scripts, thus enhancing their productivity.',
        listItem: [
          {
            title: 'Tech stack consulting',
            para:
              'Get consulted on the right tools and perfect technologies to complement your modern infrastructure and stay ahead of the evolving landscape.',
          },
          {
            title: 'Infrastructure as code implementation services',
            para:
              'We help you with end-to-end delivery, configuration, provisioning, and deployment to achieve the ideal infrastructural state.',
          },
          {
            title: 'Infrastructure optimization',
            para:
              'Prime your IT infrastructure to perform at its optimum best with our expert infrastructure optimization services.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/infrastructure-as-code/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Custom Software</span>
        <br />
        Development Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'DevOps is more than just tools, frameworks, and processes— it’s a culture. At Simform, we have DevOps ingrained in our DNAs, and therefore, you get to realize the actual benefits of DevOps with us.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            To keep up with the continuously evolving market trends and changing
            customer expectations, Agile-backed DevOps is the way to move
            forward. We are extraordinarily agile so that you can pivot
            strategies at will to stay ahead of the competition.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Delivery mindset',
        workingBenefitsContent: (
          <>
            Everything we do, right from using modern tools to deploying new
            DevOps practices, stems from your requirements and business goals.
            We bring an untiring commitment towards your objectives and use it
            as the metric for success.
          </>
        ),
        benefitImgAlt: 'Delivery mindset',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            We bring you unparalleled tech acumen with 120+ successful DevOps
            projects and 50+ skilled DevOps consultants & engineers.
            Consequently, you get high-quality digital products that resonate
            with end-user requirements and your business objectives.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable team',
        workingBenefitsContent: (
          <>
            With successful DevOps implementations, your business will be more
            likely to grow, and then you’ll need a partner who can keep up with
            your growth. We make you success-ready with a highly flexible team
            that lets you expand without cutting corners.
          </>
        ),
        benefitImgAlt: 'Scalable team',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Crystal clear communication',
        workingBenefitsContent: (
          <>
            DevOps is all about streamlined communication between the
            development and operations teams— we apply the same principle while
            working with you. With language no bar, you are always on the same
            page with your extended team.
          </>
        ),
        benefitImgAlt: 'Crystal clear communication',
      },
    ],
  },

  devApproachProps: {
    heading: (
      <>
        Our <span className="highlight-head">DevOps</span> approach
      </>
    ),
    approachContents: [
      {
        approachBlkClass: 'sage-green',
        approachHeading: 'Comprehensive Assessment & Planning',
        approachContent: (
          <>
            Every development decision undergoes extensive assessment and
            infallible planning to ensure success. From drawing the DevOps
            roadmap to identifying toolchains, we put business goals and
            stakeholders’ interests at the forefront of every decision.
          </>
        ),
        approachImgSrc: images.compAsmtPlanning,
        approachImgAlt: 'Comprehensive Assessment & Planning',
      },
      {
        approachBlkClass: 'pale-orange',
        approachHeading: 'IaC for simpler infrastructure management',
        approachContent: (
          <>
            We deploy infrastructure as code for superior management.
            Automations help provision, configuring, and controlling
            environments, with developers defining infrastructure specs with a
            domain-specific language.
          </>
        ),
        approachImgSrc: images.IacinfrasManagement,
        approachImgAlt: 'IaC for simpler infrastructure management',
      },
      {
        approachBlkClass: 'robin-s-egg',
        approachHeading: 'Continuous Integration (CI)',
        approachContent: (
          <>
            Keep evolving and updating the product by merging new code to the
            existing trunk. Even with multiple contributors, automated builds
            and tests facilitated continuous code merging to the existing trunk.
          </>
        ),
        approachImgSrc: images.continuousIntegration,
        approachImgAlt: 'Continuous Integration (CI)',
      },
      {
        approachBlkClass: 'carnation',
        approachHeading: 'Continuous Deployment (CD)',
        approachContent: (
          <>
            Accelerate feedback loop and keep the software release-ready with
            every change being directly released if it has passed through all
            pipeline stages. It allows devs to focus on improving products
            without worrying about release dates.
          </>
        ),
        approachImgSrc: images.continuousDeployment,
        approachImgAlt: 'Continuous Deployment (CD)',
      },
      {
        approachBlkClass: 'sky-blue',
        approachHeading: 'Stronger Security',
        approachContent: (
          <>
            Automated tests ensure superior product quality and fewer bugs to
            deal with at later stages. It helps accelerate delivery as well as
            improve customer satisfaction.
          </>
        ),
        approachImgSrc: images.strongerSecurity,
        approachImgAlt: 'Stronger Security',
      },
      {
        approachBlkClass: 'perrywinkle',
        approachHeading: 'Resource Planning',
        approachContent: (
          <>
            Improve profits with judicious use of available resources. We deploy
            flexible strategies to scale up or down as per business requirements
            and give stakeholders the capability to pivot at any stage.
          </>
        ),
        approachImgSrc: images.resourcePlanning,
        approachImgAlt: 'Resource Planning',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  awardsRecognitionProps: {
    heading: 'Awards & Recognition',
    awardBlockProps: [
      // {
      //   awardBlockImg: images.microsoftgold,
      // },
      {
        awardBlockImg: images.awsPartnerLogo,
      },
      {
        awardBlockImg: images.cmmiLogo,
      },
      {
        awardBlockImg: images.clutchGlobalLogo,
      },
      {
        awardBlockImg: images.theSteviesLogo,
      },
      {
        awardBlockImg: images.glassdoorfullLogo,
      },
      {
        awardBlockImg: images.GPTWLogo,
      },
      {
        awardBlockImg: images.atechchrunch,
      },
      {
        awardBlockImg: images.awwwardswebsitelogo,
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'What are the main principles of DevOps?',
        faqPara: (
          <>
            DevOps is based on the idea of improved collaboration between
            development and operations teams, so much so that they virtually
            function as a single unit working towards common goals. With this
            improved and collaborative culture, the definition of DevOps extends
            to the following principles:
            <br />
            <br />
            <ul>
              <li>Collaborative effort</li>
              <li>End-to-end responsibility</li>
              <li>Continuous improvement with feedback loops</li>
              <li> Automate wherever possible</li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'Who used DevOps?',
        faqPara: (
          <>
            DevOps has become the de-facto standard for any organization eyeing
            growth with a digital product involved in the equation. Some popular
            names practicing DevOps include Amazon, Netflix, Walmart, Facebook,
            Adobe, Sony, and more.
          </>
        ),
      },
      {
        faqTitle: 'Why is DevOps needed?',
        faqPara: (
          <>
            The digital landscape is constantly changing, with customer
            expectations evolving rapidly. To be able to not just survive but
            thrive in such challenging situations, DevOps has become the go-to
            strategy for organizations. The DevOps practices accelerate software
            delivery with CI/CD and enable organizations to update software at a
            much faster pace. Additionally, the software development life cycle
            contains fewer bugs and is more stable because of all the
            automation. <br />
            <br />
            DevOps practices also shift the focus from tools to people. This
            enables a productive and collaborative environment that reflects
            well at the end product.
          </>
        ),
      },
      {
        faqTitle:
          'What are the benefits that an organization can get with DevOps transformation?',
        faqPara: (
          <>
            An organization can expect multiple benefits upon embracing the
            DevOps culture. Some of those benefits are:
            <br />
            <br />
            <ul>
              <li>Fewer defects</li>
              <li>Less expensive bugs</li>
              <li>Better software performance</li>
              <li>Shorter time to market</li>
              <li>Efficient use of resources</li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'How DevOps increase system security in an organization?',
        faqPara: (
          <>
            The primary reason for improved security with DevOps is the
            integration of tests to continuous deployment pipelines. Since
            DevOps is all about small incremental changes and all of those
            changes undergo rigorous testing, most of the errors and bugs get
            filtered at an early stage resulting in a more secure end product.
          </>
        ),
      },
      {
        faqTitle: 'What kind of support can I expect from Simform?',
        faqPara: (
          <>
            At Simform, we help businesses through DevOps as a service for
            everything from application development to cloud deployments on
            Google Cloud, AWS, and other platforms. We walk with you through
            each stage of the process and provide our expert consultation and
            implementation services. Since we work as an extended part of your
            team, you are always in complete control of the project, with our
            project managers assisting you with their expert opinion to meet
            your business objectives. We also offer round-the-clock support to
            get all your concerns addressed as soon as possible.
          </>
        ),
      },
      {
        faqTitle:
          'Are you up to date on the latest technologies? What DevOps technologies are you using?',
        faqPara: (
          <>
            At Simform, our rich experience working on various DevOps projects
            puts us in a position to expertly suggest the best possible tools
            and technologies for specific use cases. However, we’ve also made
            sure we don’t remain caught up in ongoing projects. We make sure
            that our engineers get equipped with all the new tools and trends
            and use the improved acumen to deliver better results. You can see
            us using a whole range of tools for DevOps implementations. Some of
            the tools we often use are
            <br />
            <br />
            <ul>
              <li>GitHub</li>
              <li>Bitbucket</li>
              <li>Kubernetes</li>
              <li>Docker</li>
              <li>Prometheus</li>
              <li>AppDynamics</li>
              <li>Splunk</li>
              <li>Ansible</li>
              <li>Jenkins</li>
              <li>IBM UrbanCode</li>
              <li>Test.ai</li>
              <li>Selenium, etc.</li>
            </ul>
          </>
        ),
      },
      {
        faqTitle:
          'How many years of experience do you have in DevOps services and different automation and integration tools?',
        faqPara: (
          <>
            We’ve been delivering expert DevOps implementations, including
            DevOps testing services for more than seven years now. Over the
            years, we have developed strategic expertise to help our partners
            with smart DevOps solutions and continue exploring newer ways to
            deliver improved DevOps services.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/cloud-deployment-models-vm-iaas-containers-serverless/',
        featurecardTitle:
          'How to Choose the Right Cloud Deployment Model for your Application?',
      },
      {
        blogLink: 'https://www.simform.com/blog/react-native-app-performance/',
        featurecardTitle:
          'React Native Performance: Major issues and insights on improving your app’s performance',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-testing/',
        featurecardTitle:
          'Mobile App Testing: Challenges, Types and Best Practices',
      },
    ],
  },
}
